<template>
  <div id="menu">
    <el-dialog
      :title="menuFormTitle"
      width="680px"
      :visible.sync="menuDialogVisible"
      :close-on-click-modal="false"
      @close="menuDialogClose"
    >
      <el-form
        ref="menuFormRef"
        :model="menuForm"
        :rules="menuFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="menuForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="图标" prop="icon">
              <el-input v-model="menuForm.icon" placeholder="请输入图标" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由" prop="route">
              <el-input v-model="menuForm.route" placeholder="请输入路由" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="父菜单" prop="parentId">
              <!-- [{ id: 0, name: '顶级菜单' }].concat(menuTree) -->
              <el-select-tree
                v-model="menuForm.parentId"
                placeholder="请选择父菜单"
                :data="[{ id: 0, name: '顶级菜单', children: menuTree }]"
                :props="{ value: 'id', label: 'name', children: 'children' }"
                :disabled-values="[]"
                :disabled="false"
                :multiple="false"
                default-expand-all
                check-strictly
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px">
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="menuForm.type">
                <el-radio :label="1">
                  菜单
                </el-radio>
                <el-radio :label="2">
                  权限
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权限编码" prop="permissionCode">
              <el-input v-model="menuForm.permissionCode" placeholder="请输入权限编码" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="menuForm.sort" placeholder="请输入排序" controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="menuDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="menuFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item>
        <el-button
          v-if="checkPermission(['MENU_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="menuTableRef"
      :data="menuTreeWithPermission"
      :tree-props="{children: 'children'}"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
      @row-click="handleRowClick"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column label="图标" width="80">
        <template slot-scope="scope">
          <i :class="scope.row.icon" />
        </template>
      </el-table-column>
      <el-table-column prop="route" label="路由" />
      <el-table-column label="类型" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.type === 1 ? '菜单' : '权限' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="permissionCode" label="权限编码" />
      <el-table-column prop="sort" label="排序" width="80" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.type === 1 && checkPermission(['MENU_ADD'])"
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="handleAdd(scope.$index, scope.row)"
          >
            新增
          </el-button>
          <el-button
            v-if="(!scope.row.children || scope.row.children.length === 0) && checkPermission(['MENU_ADD'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['MENU_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  addMenu,
  deleteMenu,
  updateMenu,
  selectMenuInfo,
  selectMenuTree,
  selectMenuTreeWithPermission
} from '@/api/system/menu'

export default {
  data () {
    return {
      menuDialogVisible: false,
      menuFormTitle: '',
      menuForm: {
        id: '',
        name: '',
        icon: '',
        route: '',
        parentId: '',
        type: '',
        permissionCode: '',
        sort: ''
      },
      menuFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}],
        parentId: [{ required: true, message: '父菜单不能为空', trigger: ['blur', 'change']}],
        type: [{ required: true, message: '类型不能为空', trigger: ['blur', 'change']}]
      },
      menuTree: [],
      menuTreeWithPermission: []
    }
  },
  created () {
    selectMenuTree().then(res => {
      this.menuTree = res
    })
    selectMenuTreeWithPermission().then(res => {
      this.menuTreeWithPermission = res
    })
  },
  methods: {
    menuDialogClose () {
      this.$refs.menuFormRef.resetFields()
    },
    menuFormSubmit () {
      this.$refs.menuFormRef.validate(async valid => {
        if (valid) {
          if (this.menuFormTitle === '新增菜单') {
            await addMenu(this.menuForm)
          } else if (this.menuFormTitle === '修改菜单') {
            await updateMenu(this.menuForm)
          }
          selectMenuTree().then(res => {
            this.menuTree = res
          })
          selectMenuTreeWithPermission().then(res => {
            this.menuTreeWithPermission = res
          })
          this.menuDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.menuTableRef.toggleRowExpansion(row)
    },
    handleAdd (index, row) {
      if (row !== undefined) {
        this.menuForm.parentId = row.id
      }
      this.menuFormTitle = '新增菜单'
      this.menuDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMenu(row.id)
        selectMenuTree().then(res => {
          this.menuTree = res
        })
        selectMenuTreeWithPermission().then(res => {
          this.menuTreeWithPermission = res
        })
      })
    },
    handleUpdate (index, row) {
      this.menuFormTitle = '修改菜单'
      this.menuDialogVisible = true
      selectMenuInfo(row.id).then(res => {
        this.menuForm.id = res.id
        this.menuForm.name = res.name
        this.menuForm.icon = res.icon
        this.menuForm.route = res.route
        this.menuForm.parentId = res.parentId
        this.menuForm.type = res.type
        this.menuForm.permissionCode = res.permissionCode
        this.menuForm.sort = res.sort
      })
    }
  }
}
</script>

<style>
</style>
